body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {

}

.ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline.ck-blurred {
    border: 1px solid #000;
}

.MuiButtonBase-root.MuiListItem-root.jss149.MuiListItem-gutters.MuiListItem-button {
    padding-left: 27px !important;
}

.MuiListItem-gutters {
    padding-left: 7px !important;
}

.MuiListItem-root {
    padding-top: 2px !important;
    padding-bottom: 2px !important;
}

.MuiList-root {
    white-space: normal !important;
    padding-left: 0 !important;
}

.MuiTypography-body1 {
    font-size: 13px !important;
}

.MuiCollapse-entered {
    padding-left: 20px !important;
}

.anchorButton {
    text-decoration: none !important;
    color: #dc3545 !important;
}

.btn:hover .anchorButton, .anchorButton:hover {
    color: #ffffff !important;
}


.deliverable .MuiTableCell-root {
    border-left: 1px solid rgb(217 212 212) !important;
    padding-left: 5px !important;
    padding-right: 5px !important;
}
.photo-div {
    position: absolute;
    right: 20px;
    top: 10px;
}

.assetTable th {
    padding: 6px 10px;
}

.assetTable tr {
    border-bottom: 1px solid #e3e3e3;
}

.assetTable tr:last-child {
    border-bottom: none;
}
.input-label-common {
    margin-bottom: 0px !important;
}
.login-form-button {
    background-color: #f44336;
}


.logo {
    width: 31px;
    height: 31px;
    background: rgba(255, 255, 255, .2);
    margin: 16px 28px 16px 0;
    float: left;
    background-image: url(/static/media/citylogo-sm.35d8ee4d.ico);
    background-color: #cccccc;
}

.login-form {
    max-width: 300px;
}

.login-form-forgot {
    float: right;
}

.login-form-button {
    width: 100%;
}


.small-box {
    border-radius: .25rem;
    box-shadow: 0 0 1px rgba(0, 0, 0, .125), 0 1px 3px rgba(0, 0, 0, .2);
    display: block;
    margin-bottom: 20px;
    position: relative;
    height: 120px;
}

.small-box-bom {
    min-height: 170px !important;
    margin-bottom: 0 !important;
}

.small-box-bom > .inner {
    padding: 10px 10px 0 !important;
}

.bg-info {
    background-color: #17a2b8 !important;
}

.bg-success {
    background-color: #28a745 !important;
}

.bg-warning {
    background-color: #ab6a6a !important;
}

.bg-danger {
    background-color: #dc3545 !important;
}

.small-box > .inner {
    padding: 10px;
}

.small-box h3 {
    font-size: 16px;
    font-weight: bold;
    margin: 0 0 10px 0;
    padding: 0;
    white-space: nowrap;
    z-index: 5;
    color: #fff
}

.small-box p {
    font-size: 1rem;
    z-index: 5;
    color: #fff
}

.small-box .icon {
    color: rgba(0, 0, 0, .15);
    z-index: 0;
}

.small-box .icon > svg {
    font-size: 90px;
    position: absolute;
    right: 15px;
    top: 15px;
    -webkit-transition: all .3s linear;
    transition: all .3s linear;
}

.ion-bag::before {
    content: "\F110";
}

.small-box > .small-box-footer {
    background: rgba(0, 0, 0, .1);
    color: rgba(255, 255, 255, .8);
    display: block;
    padding: 3px 0;
    position: relative;
    text-align: center;
    text-decoration: none;
    z-index: 10;
    margin-top: 17px;
}

.bom {
    margin-top: 0 !important;
}

.MuiTableRow-root.MuiTableRow-footer {
    display: -webkit-flex;
    display: flex;
}

.recharts-wrapper {
    margin: 0 auto;
}
.highLight-color input{
    color: #ffffff !important;
}
