.small-box {
    border-radius: .25rem;
    box-shadow: 0 0 1px rgba(0, 0, 0, .125), 0 1px 3px rgba(0, 0, 0, .2);
    display: block;
    margin-bottom: 20px;
    position: relative;
    height: 120px;
}

.small-box-bom {
    min-height: 170px !important;
    margin-bottom: 0 !important;
}

.small-box-bom > .inner {
    padding: 10px 10px 0 !important;
}

.bg-info {
    background-color: #17a2b8 !important;
}

.bg-success {
    background-color: #28a745 !important;
}

.bg-warning {
    background-color: #ab6a6a !important;
}

.bg-danger {
    background-color: #dc3545 !important;
}

.small-box > .inner {
    padding: 10px;
}

.small-box h3 {
    font-size: 16px;
    font-weight: bold;
    margin: 0 0 10px 0;
    padding: 0;
    white-space: nowrap;
    z-index: 5;
    color: #fff
}

.small-box p {
    font-size: 1rem;
    z-index: 5;
    color: #fff
}

.small-box .icon {
    color: rgba(0, 0, 0, .15);
    z-index: 0;
}

.small-box .icon > svg {
    font-size: 90px;
    position: absolute;
    right: 15px;
    top: 15px;
    transition: all .3s linear;
}

.ion-bag::before {
    content: "\f110";
}

.small-box > .small-box-footer {
    background: rgba(0, 0, 0, .1);
    color: rgba(255, 255, 255, .8);
    display: block;
    padding: 3px 0;
    position: relative;
    text-align: center;
    text-decoration: none;
    z-index: 10;
    margin-top: 17px;
}

.bom {
    margin-top: 0 !important;
}

.MuiTableRow-root.MuiTableRow-footer {
    display: flex;
}

.recharts-wrapper {
    margin: 0 auto;
}
.highLight-color input{
    color: #ffffff !important;
}