.logo {
    width: 31px;
    height: 31px;
    background: rgba(255, 255, 255, .2);
    margin: 16px 28px 16px 0;
    float: left;
    background-image: url("citylogo-sm.ico");
    background-color: #cccccc;
}

.login-form {
    max-width: 300px;
}

.login-form-forgot {
    float: right;
}

.login-form-button {
    width: 100%;
}

