.App {

}

.ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline.ck-blurred {
    border: 1px solid #000;
}

.MuiButtonBase-root.MuiListItem-root.jss149.MuiListItem-gutters.MuiListItem-button {
    padding-left: 27px !important;
}

.MuiListItem-gutters {
    padding-left: 7px !important;
}

.MuiListItem-root {
    padding-top: 2px !important;
    padding-bottom: 2px !important;
}

.MuiList-root {
    white-space: normal !important;
    padding-left: 0 !important;
}

.MuiTypography-body1 {
    font-size: 13px !important;
}

.MuiCollapse-entered {
    padding-left: 20px !important;
}

.anchorButton {
    text-decoration: none !important;
    color: #dc3545 !important;
}

.btn:hover .anchorButton, .anchorButton:hover {
    color: #ffffff !important;
}


.deliverable .MuiTableCell-root {
    border-left: 1px solid rgb(217 212 212) !important;
    padding-left: 5px !important;
    padding-right: 5px !important;
}