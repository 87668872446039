.photo-div {
    position: absolute;
    right: 20px;
    top: 10px;
}

.assetTable th {
    padding: 6px 10px;
}

.assetTable tr {
    border-bottom: 1px solid #e3e3e3;
}

.assetTable tr:last-child {
    border-bottom: none;
}